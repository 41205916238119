ng-casto-common-customer-card-information {
  .card-container {
    & > svg {
      max-width: 100%;
      max-height: 120px;
      margin: 0 auto;
      filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
    }
  }

  .card-description {
    margin-top: 1em !important;

    .card-state {
      border-radius: 20px;
      font-weight: bold;
      text-align: center;
      font-size: .8em;
      max-width: 9em;

      &.active {
        background-color: $yellow-casto;
      }
      &.inactive {
        background-color: $light-grey;
      }
    }
  }

  .card-name {
    font-weight: bold;
    font-size: 1.2em;
    color: $primary-color;
    margin: .3em 0;
  }

  .card-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    & > p {
      margin-bottom: .2em;
    }
  }

  .button-container {
    margin-top: 2em;
    flex: 1;
    display: flex;
    flex-direction: column;

    ng-casto-btn, a {
      margin-bottom: .5em;
      text-align: center;
    }

    .casto-link {
      margin-top: .5em;
    }
  }

  .customer-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;

    &:not(:last-of-type){
      border-bottom: 1px solid $light-grey-active;
    }

    span {
      padding: .5em;

      &.key {
        align-self: center;
      }

      &.val {
        font-weight: bold;

        &.free {
          color: $primary-color;
        }
        &.premium {
          color: $yellow-casto;
        }
      }
    }

    label {
      margin-left: .2em;
      margin-right: .5em;
    }
  }

  .card-img {
    text-align: center;
    padding: 1em 0;

    img {
      max-width: 200px;
    }
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .casto-link {
      align-self: center;
    }
  }

  .btn-block ng-casto-btn {
    margin: 0 .5em;
  }

  .form-row:last-child {
    margin-bottom: 0 !important;

    .casto-link {
      margin-bottom: 0;
    }
  }
}
