ng-casto-customer-login {
  .login-container {
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    margin-top: -$header-height;
    background-image: url("/assets/img/login/login-background.jpg");
    background-size: cover;
    background-position: center;

    #login-header {
      width: 261px;
      margin: 29px auto;

      #login-title {
        width: 261px;
      }

      #login-subtitle {
        color: $white;
        display: inline;
        margin-left: 10px;
      }
    }

    #call-to-actions {
      margin: 37px 60px;

      ng-casto-btn + ng-casto-btn {
        margin-top: 16px;
      }
    }

    .container {
      display: flex;
      justify-content: center;
      gap: 1em;
      height: 80vh;
      align-items: center;
      $login-card-border-radius: 50px;
      $login-card-width: 486px;

      ng-casto-customer-login-form, ng-casto-customer-forgotten-password {
        background-color: rgba(253, 253, 253, 0.8);
      }

      ng-casto-customer-create-card-info {
        border-radius: 0 $login-card-border-radius 0 0;
        background-color: $light-blue-hover;
      }

      ng-casto-customer-registration {
        border-radius: $login-card-border-radius $login-card-border-radius 0 0;
        background-color: $light-blue-hover;

        &.login-card {
          width: $login-card-width * 2;
          height: 100% !important;
        }
      }

      .login-card {
        width: $login-card-width;
        height: 545px;
        padding: 36px 73px;

        .form-card {
          background-color: transparent;
          border: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .login-card-header {
            color: $primary-color;
            text-align: center;

            svg {
              width: 55px;
              height: 9px;
            }

            .login-card-title {
              height: 35px;
            }

            .login-card-subtitle {
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              margin-top: 16px;
              border-bottom-width: 0;
              text-decoration: none;
              color: $primary-color;
            }
          }

          .login-card-body {
            .form-row {
              +.form-row {
                margin-top: 19px;
              }

              .form-col {
                label {
                  font-size: 13px;
                  font-weight: 400;
                  margin-bottom: 2px;
                }

                input[type="text"], input[type="password"] {
                  border-radius: 6px;
                  border-color: $primary-color;
                }

                input[type="checkbox"] {
                  margin-right: 5px;
                }


                .forgotten-password-link {
                  font-size: 12px;
                }
              }
            }

            .error, .valid {
              margin: 15px auto;
              text-align: center;
            }

            .error {
              color: $text-error;
              font-size: 1.2em;
            }

            .valid {
              color: $primary-color;
              font-size: 1.2em;
            }
          }

          .login-card-footer {
            text-align: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  ng-casto-customer-login {
    #call-to-actions {
      display: none;
    }

  }
}

@media (max-width: 767px) {
  ng-casto-customer-login {
    ng-casto-btn {
      width: 100%;
    }

    .login-container {
      justify-content: space-between;

      #login-header {
        margin: 75px auto 30px;
      }

      .container {
        padding: 0;
        height: auto;

        ng-casto-customer-login-form,
        ng-casto-customer-forgotten-password,
        ng-casto-customer-create-card-info,
        ng-casto-customer-registration {
          border-radius: 50px 50px 0 0;
        }

        ng-casto-customer-create-card-info {
          img {
            max-height: 217px;
          }
        }

        ng-casto-customer-registration {
          &.login-card {
            height: 630px;
          }
        }

        .login-card {
          height: 500px;
          border-radius: 50px 50px 0 0;
          padding: 36px 36px 18px 36px;

          .form-card {
            .login-card-body {
              .form-row {
                margin-bottom: 0.5em;

                +.form-row {
                  margin-top: 0.5em;
                }

                .form-col {
                  flex: auto;

                  +.form-col {
                    margin-top: 0.5em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.input-icon .fas {
  position: absolute;
  top: 15px;
  cursor: pointer;
  font-size: 18px;

  &.fa-eye {
    right: 16px;
  }

  &.fa-eye-slash {
    right: 15px;
  }
}