ng-casto-card-number-search-customer {
    #cardFidelity {
        margin: auto;
    }

    .title {
        margin-bottom: 1em;
        text-align: center;
        font-size: large;
    }

    .row-selected {
        background-color: rgba(0, 120, 215, .25) !important;
    }

    .subtitle {
        margin-bottom: 2em;
    }

    .separator { 
       color: #0078d4;
    }

    .com-plus-link-icon {
        width: 6em;
        height: 3em;
        border-radius: 20px;
        background-color: #ffdd00;
        text-align: center;
        font-size: x-small;
        font-weight: bold;
        display: table;
    }

    .email-choice-input {
        margin: 5%;

        .description {
            text-align: center;
            align-self: center;
        }
    }

    .validate {
        margin-right: 0.5em;
    }
}