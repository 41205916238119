ng-casto-modals ng-casto-order-detail {
  .text {
    p  {
      margin: .5em 0;

      & > span {
        font-weight: bold;
      }
    }

    .product-detail {
      margin-top: 2em;

      h4 {
        font-size: 1.1em;
        text-transform: uppercase;
      }

      p {
        text-align: right;
        font-weight: bold;
      }
    }
  }
  table {
    width: 100%;
  }
}
