ng-casto-collegue-customer-history {
  .title-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5em 0 1.1em 0;

    .title {
      display: inherit;
      margin-bottom: 0;

      .icon {
        display: flex;
        align-items: center;
      }

      .text {
        align-self: center;
      }
    }

    .btn-container {
      display: flex;
      gap: 1em;

      ng-casto-btn {
        font-size: 1em;
      }
    }
  }

  .toggle-context-container {
    margin-top: 2em;
    border-bottom: 1px solid $light-grey-active;

    .toggle-context-btn {
      padding: 1em;
      display: inline-block;
      border-bottom: 2px solid transparent;
      color: $light-grey-active;
      cursor: pointer;
      transition: all 200ms ease-in-out;

      &.active {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
      }

      &:hover {
        color: $primary-color-active;
      }

    }
  }

  ng-casto-common-customer-order-history, ng-casto-common-customer-points-history {
    .result-table {
      padding: 0;

      .ngx-datatable.material:not(.cell-selection) {
        border-radius: 2px;
        border: 1px solid $light-blue;

        .datatable-header {
          &-cell {
            background-color: $primary-color;
            color: white;
            font-weight: bold !important;
            padding: 1rem;
          }
        }


        .datatable-body-row {

          .datatable-body-cell {
            transition: all 200ms ease-in-out;
          }

          &:hover {
            background: none !important;

            .datatable-row-group{
              background: none !important;
            }
          }
        }

        .datatable-row-wrapper:nth-of-type(even) .datatable-body-row .datatable-body-cell{
          background-color: $primary-color-transparent;
        }

        .datatable-footer {
          .datatable-pager li.active a {
            background-color: $primary-color;
            border-radius: $border-radius;
          }
        }
      }
    }
  }

  ng-casto-common-customer-order-history {
    .result-table .ngx-datatable.material:not(.cell-selection) .datatable-row-wrapper .datatable-body-row {
      cursor: pointer;

      &:hover .datatable-body-cell {
        background-color: $light-blue;
      }
    }
  }
}

@media (max-width: 991px) {
  ng-casto-collegue-customer-history {
    .title-block {
      flex-direction: column;
      gap: 1em;

      .btn-container {
        ng-casto-btn {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  ng-casto-collegue-customer-history {
    .title-block {
      .btn-container {
        flex-wrap: wrap;
      }
    }
  }
}
