ng-casto-customer-history {
  ng-casto-common-customer-order-history {
    .result-table {
      padding: 0;

      .ngx-datatable.material:not(.cell-selection) {
        border-radius: 2px;

        .datatable-header {
          &-cell {
            color: black;
            font-size: .9em !important;
            padding: 0.2em 1em !important;
          }
        }


        .datatable-body-row {
          cursor: pointer;

          .datatable-body-cell {
            transition: all 200ms ease-in-out;
            border-top: none !important;
          }

          &:hover .datatable-body-cell {
            background-color: $light-blue-hover !important;
          }
        }

        .datatable-row-wrapper:nth-of-type(odd) .datatable-body-row .datatable-body-cell{
          background-color: $light-blue;
        }

        .datatable-footer {
          .datatable-pager li.active a {
            background-color: $primary-color;
            border-radius: $border-radius;
          }
        }
      }
    }
  }
}
