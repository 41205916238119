ng-casto-colleague-reporting {

  .reporting-block {
    .warning {
      color: red;
      font-size: 0.9rem;
    }

    .reporting-item {
      height: 100%; 
      
      .date-block {
        padding-bottom: 1em;
      }

      .comparison {
        input[type=checkbox] {
          vertical-align: middle;
        }
  
        span {
          width: 100%;
          margin-bottom: 5px;
        }
  
        label {
          position: static;
          display: inline-block;
          margin-left: 10px;
          color: $darker-grey;
          font-weight: 500;
          font-size: 0.8rem;
          vertical-align: -webkit-baseline-middle;
        }
      }

      .no-data {
        text-align: center;
      }

      .multiselect-block > p-multiselect > div {
        width: 100%;
      }

      .form-title > .download {
        float: right;
      }

      .tabs-container {
        margin-top: 2em;
        margin-bottom: 2em;
        user-select: none;
        text-align: center;

        .tab-btn {
          padding: 1em;
          display: inline-block;
          border-bottom: 1px solid $light-grey-active;
          color: $light-grey-active;
          cursor: pointer;
          transition: all 200ms ease-in-out;

          &.active {
            color: $primary-color;
            border-bottom: 2px solid $primary-color;
          }

          &:hover {
            color: $primary-color-active;
          }
        }
      }
    }
  }

  .colleague-table {
    padding: 0 0.5em;

    .ngx-datatable.material:not(.cell-selection) {
      border-radius: 2px;

      .datatable-header {
        &-cell {
          color: black;
          font-size: 0.9em !important;
          padding: 0.2em 1em !important;
        }
      }

      .datatable-body-row {
        cursor: pointer;

        .datatable-body-cell {
          transition: all 200ms ease-in-out;
          border-top: none !important;

          .datatable-body-cell-label {
            ng-casto-btn {
              padding: 0;
              min-height: 0;
              min-width: 0;

              &::before {
                display: none;
              }
            }
          }
        }

        &:hover .datatable-body-cell {
          background-color: $light-blue-hover !important;
        }
      }

      .datatable-row-wrapper:nth-of-type(odd) .datatable-body-row .datatable-body-cell {
        background-color: $light-blue;
      }

      .datatable-footer {
        .datatable-pager li.active a {
          background-color: $primary-color;
          border-radius: $border-radius;
        }
      }
    }
  }
}

.p-dropdown {
  margin-bottom: 10px;
}

div[echarts] {
  margin-bottom: 20px;
}
