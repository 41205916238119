ng-casto-collegue-create-card {
  .form-card {
    margin: 1em 0;
    display: flex;
    flex-direction: column;

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    .form-group {
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .selector-row {
      justify-content: center;
    }

    .casto-selector {
      input + label {
        margin-bottom: .5em;
      }

      &.card-selector {
        width: 400px;

        input + label {
          padding: 24px 16px;

          .card-visuel {
            height: 80px;
            position: relative;

            & > svg {
              height: 100%;
              width: 100%;
              filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
            }
          }

          .icon-visuel {
            height: 32px;

            & > svg {
              height: 100%;
              width: 100%;
            }
          }

          .price {
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            padding: 3px 10px;
            background-color: $yellow-casto;
            border-radius: 20px;
            font-family: "Casto Pro Bold";
          }

          p:not(.card-name) {
            font-family: 'Casto Pro Regular';
            font-weight: normal !important;
            color: $dark-grey;
          }

          .card-name {
            margin-top: 1.2em;
          }

          .casto-link {
            pointer-events: all;
          }
        }

        &:checked+label {
          .card-name {
            color: $primary-color;
          }
        }
      }

      &.project-selector {
        flex: 1 0 30%;

        input[type="checkbox"], input[type="radio"] {
          &+label {
            & > svg {
              max-width: 80px;
              height: 30px;
              fill: $primary-color;
            }
          }

          &:checked+label {
            color: $primary-color;
          }
        }
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1em;

    ng-casto-btn {
      margin-right: 1em;
    }
  }

  .error-container {
    color: $text-error;
    font-size: 1.1em;
    font-weight: bold;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .user-item {
    display: flex;
    flex-direction: row;
    vertical-align: middle;

    &-icon svg {
      height: 45px;
      width: 37px;
    }

    &-description {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
    }

    &-card-state {
      margin-bottom: .5em;

      & > span {
        padding: 3px 10px;
        background-color: $yellow-casto;
        font-size: .7em;
        text-align: center;
      }
    }

    &-name {
      font-family: 'Casto Pro Bold';
      color: $primary-color;
      font-size: 1.1em;
    }

    &-mail {
      margin: .5em 0;
      color: $grey-second;
    }
  }

  ng-casto-card-infos {
    margin-top: 1em;

    button.close {
      display: none;
    }
  }

  ng-casto-payment-contribution-choices {
    .form-group {
      .selector-row {
        justify-content: center !important;

        .card-selector {
          width: 11em;
          flex: inherit;
        }
      }
    }
  }

  ng-casto-finalization-creation {
    .casto-card {
      background-color: $white;
      border-radius: 8px;
      box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
      display: flex;
      padding: 0 25px;
      gap: 1.5em;
      align-items: center;

      .card-icon {
        width: 7.4em;
      }
    }

    #qr-code {
      background-image: url("/assets/img/background-qr-code.svg");
      background-size: cover;

      img {
        border: 1px solid $primary-color;
        margin: 2em;
      }
    }

    .message {
      margin-top: 1em;
    }
  }

  ng-casto-card-create {
    .casto-card {
      .card-icon {
        height: 11em;
      }
    }

    .message {
      margin-top: -0.5em;
    }
  }

  ng-casto-finalization-creation, ng-casto-card-create {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      margin-top: 3em;

      .casto-card {
        position: relative;

        .card-icon {
          filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
        }

        .success-icon {
          height: 3em;
          width: 3em;
          background-color: $primary-color-active;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -4px;
          right: 26px;

          svg {
            height: 57%;
            width: 57%;
          }
        }

        .warning-icon {
          height: 3em;
          width: 3em;
          background-color: $warning;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -1.5em;
          right: -1.5em;

          svg {
            height: 1.5em;
            width: 1.5em;
          }
        }
      }

      .message {
        text-align: center;
        margin-bottom: 0.5em;

        h2 {
          font-size: 1.7em;
          margin-bottom: 0.6em;
        }

        p {
          margin-bottom: 1em;

          &.subtitle {
            margin-bottom: 0.1em;
          }
        }
      }

      .actions {
        ng-casto-btn + ng-casto-btn {
          margin-left: 1em;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .casto-selector {
    max-width: 50%;

    &.project-selector {
      flex: 1 0 45% !important;
    }
  }

  ng-casto-colleague-pre-creation {
    .error-container {
      padding-bottom: 2em;
    }

    .user-item {
      flex-direction: column;

      &-icon {
        text-align: center;
      }

      &-description {
        margin-left: 0;
        align-items: center;
      }
    }
  }

  ng-casto-payment-contribution-choices {
    .card-selector {
      max-width: inherit;
    }
  }
}
