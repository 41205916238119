ng-casto-resend-payment-link-sms {
  .form-row {
    display: flex;
    justify-content: center;

    .form-col {
      max-width: 20em;
    }
  }
}
