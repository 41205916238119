ng-casto-modals {
  background-color: rgba(204, 221, 236, 0.7);
  font-size: 1rem;
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  position: fixed;
  transform: scale(1.1);
  top: 0;
  transition: opacity 0.2s ease-out, transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), z-index 0s 0.3s;
  width: 100%;
  z-index: 999999999999;
  display: block;
  text-align: center;
  align-content: center;

  & > {
    ng-casto-loader {
      display: none;
    }
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
    z-index: 999999999999;
    transform: scale(1);
  }

  &.loading {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 1;
    pointer-events: auto;
    z-index: 70;
    text-align:center;

    ng-casto-loader {
      display: inline-block;
      vertical-align: middle;
    }

    &:before{
      height:100%;
      width:1px;
      vertical-align: middle;
      display: inline-block;
      content:'';
    }
  }

  .content {
    background-color: white;
    border: 1px solid $blue-border;
    border-radius: $border-radius;
    margin: 0 auto 0;
    padding: 30px 0;
    position: relative;
    width: 70%;
    max-width: 850px;
    display: block;
    text-align: left;

    .inner-content {
      font-size: 1rem;
      padding: 30px;
      text-align: center;
    }

    header {
      .wrap {
        margin: 0 auto;
        position: relative;

        padding: 0 40px;

        h1 {
          &.title {
            display: flex;
            flex-direction: row;
            vertical-align: middle;
            justify-content: center;

            & > .text {
              align-self: end;
            }
          }
        }

        h2 {
          &.modal-subtitle {
            text-align: center;
            margin-bottom: 1.1em;
            color: $primary-color-active;
          }
        }
      }
    }

    .close {
      display: inline-block;
      position: absolute;
      top: -20px;
      right: 10px;
      height: 1.4285714286rem;
      width: 1.4285714286rem;
      margin: 0;
      z-index: 3;
      text-align: center;
      transition: color 0.3s ease-out;
      fill: $light-grey;
      cursor: pointer;
      border: 0;
      background: none;

      span {
        height: 30px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        vertical-align: middle;
        display: inline-block;
        fill: $darker-grey;
        margin: 0;
        transition: fill 0.3s ease-out;
        &:not([class^="icon-"]) {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          white-space: nowrap;
          width: 1px;
        }

        svg {
          height: 100%;
          width: 100%;
        }
      }

    }

    &.small {
      width: 70%;
      max-width: 327px;
    }
  }

  .text {
    padding-top: 0;
    text-align: left;

    .info {
      font-weight: lighter;
      color: $dark-grey;
      font-size: .8rem;
    }

    input {
      width: 100%;
    }
  }



  .popin-footer {
    text-align: center;
    margin: 1.5em 2em 0 2em;

    ng-casto-btn {
      margin: 0 .5em;
    }
  }

  table.modal-table {
    font-size: .8rem;
    margin-bottom: 2em;

    thead {
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
      font-weight: bold;
    }

    tbody > tr {
      padding-bottom: 1em;
    }

    td, th {
      padding: .5em;
    }
  }

  .form-group {
    &-title {
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
    }
  }
}

@media print {
  .print-container {
    .close {
      display: none;
    }

    .text {
      .title {
        .text {
          text-align: center;
        }

        .icon {
          display: none;
        }
      }

      .modal-table {
        tr {
          th {
            padding: 0.5em 1em;
          }

          td {
            padding: 0.5em 1em;
          }
        }
      }
    }

    .popin-footer {
      display: none;
    }
  }
}
