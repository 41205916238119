$primary-color: #0078d4;
$primary-color-active: #0063b0;
$primary-color-transparent: rgba(0, 120, 215, .05);
$yellow-casto: #ffdd00;
$yellow-casto-active: #d2b600;
$yellow-casto-secondary: #FFF9D7;
$yellow-casto-transparent: rgba(255,220,0,.2);
$light-blue: #EBF6FF;
$light-blue-hover: #E9F4FC;
$blue-border: #D0E3F3;

$odd-bg: #cce4f7;
$even-bg: #e5f2fb;

$white: #fff;
$light-grey: #adb1b3;
$light-grey-active: #d3d3d3;
$lighter-grey: #EFF0F0;

$black: #000;
$grey-second: #58626D;
$dark-grey: #848a8d;
$darker-grey: #323c41;

$error: #c74855;
$text-error: #a83b42;

$warning: #FB9F00;

$yellow-green: #9ACD32;

$table-border-color: #bdbdbd;
$search-color: #ededed;
$main-background-color: #ffffff;

$circle: clamp(1.5rem, 5vw, 3rem);
$spacing: clamp(0.25rem, 2vw, 0.5rem);

$border-radius: 4px;

$padding-vertical: 16px;
$padding-horizontal: 40px;

$header-height: 56px;