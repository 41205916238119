ng-casto-collegue-home {
  .home-block {
    padding: 0 2em;

    .institutional-block {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 3.5em;
      gap: 1em;

      .help-block {
        width: 15em;
        border: 1px solid $primary-color;
        border-radius: $border-radius;
        padding: 20px;
        text-align: center;
        transition: all 200ms ease-in-out;
        cursor: pointer;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $primary-color;

        &:hover {
          background-color: $primary-color-active;
        }
      }
    }
  }

  .intro {
    margin-bottom: 0;
    font-weight: 400;
    font-family: 'Casto Pro Regular';
    font-size: 24px;
    line-height: 32px;

    & > span {
      font-family: 'Casto Pro Bold';
      font-weight: 900;
      font-size: 34px;
    }
  }

  .subintro {
    margin-bottom: 2em;

    & > span {
      font-weight: bold;
      color: $primary-color;
    }
  }

  .intro-img {
    text-align: center;

    & > img {
      max-width: 100%;
    }
  }

  .home-item {
    margin-bottom: 2em;

    .quick-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-btn {
        display: flex;
        flex-direction: column;
        border: 1px solid $blue-border;
        border-radius: $border-radius;
        padding: 1em;
        margin: 0 .5em;
        flex: 1;

        &.btn-create-card {
          background-color: $primary-color;
          border: none;
          color: white;
          font-size: 1.2em;
          text-align: center;
          justify-content: space-evenly;
          line-height: 20px;
          cursor: pointer;
          transition: all 200ms ease-in-out;

          &:hover {
            background-color: $primary-color-active;
          }

          .card-icon > svg {
            height: 64px;
            width: 100%;
          }

          .plus-icon > svg {
            height: 40px;
            width: 100%;
          }
        }

        &.btn-search {
          flex: 2;

          .casto-input-group {
            height: 48px;
            box-shadow: 0 1px 3px 0 #2F2B431A;

            button {
              border-radius: 0 4px 4px 0;
              padding: .5em;

              div > svg {
                width: 28px;
                height: 28px;
              }
            }
          }

          a {
            font-weight: bold;
            font-size: 0.9em;
            color: $grey-second;
          }

          abp-validation-error {
            display: none;
          }
        }

        &.btn-reporting {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          cursor: pointer;
          transition: all 200ms ease-in-out;

          &:hover {
            background-color: $light-blue-hover;
          }

          div > svg {
            height: 64px;
            width: 100%;
          }
        }
      }
    }

    .avantages {
      display: flex;
      gap: 1em;

      ng-casto-card-infos {
        padding: 2em 4em;
        flex: 1;

        button.close {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  ng-casto-collegue-home {
    .home-item {
      .quick-actions {
        flex-direction: column;

        &-btn {
          margin: .5em 0;

          &.btn-search {
            flex: 1;
          }

          div > svg {
            max-width: 100px
          }
        }
      }

      .avantages {
        flex-wrap: wrap;
      }
    }
  }
}
