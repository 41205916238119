ng-casto-customer-create-card {
  .main-container.create-card {
    padding-left: 0 !important;
  }

  .form-card {
    margin: 0 1em 1em;
    background-color: white;
    border-color: $light-blue;
    display: flex;
    flex-direction: column;

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    .form-group {
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .selector-row {
      justify-content: center;
    }

    .casto-selector {
      input + label {
        margin-bottom: .5em;
      }

      &.card-selector {
        width: 400px;

        input + label {
          padding: 24px 16px;

          .card-visuel {
            height: 80px;
            position: relative;

            & > svg {
              height: 100%;
              width: 100%;
              filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
            }
          }

          .icon-visuel {
            height: 32px;

            & > svg {
              height: 100%;
              width: 100%;
            }
          }

          .price {
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            padding: 3px 10px;
            background-color: $yellow-casto;
            border-radius: 20px;
            font-family: "Casto Pro Bold";
          }

          p:not(.card-name) {
            font-family: 'Casto Pro Regular';
            font-weight: normal !important;
            color: $dark-grey;
          }

          .card-name {
            margin-top: 1.2em;
          }

          .casto-link {
            pointer-events: all;
          }
        }

        &:checked+label {
          .card-name {
            color: $primary-color;
          }
        }
      }

      &.project-selector {
        flex: 1 0 30%;

        input[type="checkbox"], input[type="radio"] {
          &+label {
            & > svg {
              max-width: 80px;
              height: 30px;
              fill: $primary-color;
            }
          }

          &:checked+label {
            color: $primary-color;
          }
        }
      }
    }


  }

  .home-block {
    margin-bottom: 2em;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1em;

    ng-casto-btn {
      margin-right: 1em;
    }
  }

  ng-casto-payment-contribution-choices {
    .form-group {
      .selector-row {
        justify-content: center !important;

        .card-selector {
          width: 11em;
          flex: inherit;
        }
      }
    }
  }

  ng-casto-finalization-creation {
    .casto-card {
      background-color: $white;
      border-radius: 8px;
      box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
      display: flex;
      padding: 0 25px;
      gap: 1.5em;
      align-items: center;

      .card-icon {
        width: 7.4em;
      }
    }

    #qr-code {
      background-image: url("/assets/img/background-qr-code.svg");
      background-size: cover;

      img {
        border: 1px solid $primary-color;
        margin: 2em;
      }
    }

    .message {
      margin-top: 1em;
    }
  }

  ng-casto-card-create {
    .casto-card {
      .card-icon {
        height: 11em;
      }
    }

    .message {
      margin-top: -0.5em;
    }
  }

  ng-casto-finalization-creation, ng-casto-card-create {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      margin-top: 3em;

      .casto-card {
        position: relative;

        .card-icon {
          filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
        }

        .success-icon {
          height: 3em;
          width: 3em;
          background-color: $primary-color-active;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -4px;
          right: 26px;

          svg {
            height: 57%;
            width: 57%;
          }
        }

        .warning-icon {
          height: 3em;
          width: 3em;
          background-color: $warning;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -1.5em;
          right: -1.5em;

          svg {
            height: 1.5em;
            width: 1.5em;
          }
        }
      }

      .message {
        text-align: center;
        margin-bottom: 0.5em;

        h2 {
          font-size: 1.7em;
          margin-bottom: 0.6em;
        }

        p {
          margin-bottom: 1em;

          &.subtitle {
            margin-bottom: 0.1em;
          }
        }
      }

      .actions {
        ng-casto-btn + ng-casto-btn {
          margin-left: 1em;
        }
      }
    }
  }

  ng-casto-finalization-creation {
    .form-card {
      height: calc(90vh - 56px - 35px - 7em);

      iframe {
        height: 100%;
      }
    }

    .actions {
      text-align: right;
    }
  }

  ng-casto-common-create-card-type {
    ng-casto-card-infos {
      margin-top: 1em;

      button.close {
        display: none;
      }
    }
  }
}
