ng-casto-collegue-administration {
  .toggle-context-container {
    margin-top: 2em;
    border-bottom: 1px solid $light-grey-active;
    user-select: none;

    .toggle-context-btn {
      padding: 0.75em;
      display: inline-block;
      border-bottom: 2px solid transparent;
      color: $light-grey-active;
      cursor: pointer;
      transition: all 200ms ease-in-out;

      &.active {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
      }

      &:hover {
        color: $primary-color-active;
      }

    }
  }

  .block {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    padding: 1em 0;

    &:not(:last-child){
      border-bottom: 1px solid $light-grey-active;
    }

    img {
      max-width: calc(100% / 4);
      margin-right: 1em;
      height: 100%;
    }

    .edit-block {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .edit-item {
        flex: 1;
        margin: .3em 0;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        &-label {
          font-weight: bold;
          margin-right: 1em;
          width: 20%;
        }

        &-value {
          width: 80%;
          word-break: break-all;
        }

        &-input {
          width: 80%;
        }
      }

      .edit-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;

        ng-casto-btn:not(:last-child){
          margin-right: 1em;
        }
      }
    }

    .setting-block {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .setting-item {
        flex: 1;
        margin: .3em 0;
        display: flex;
        flex-direction: column;
        justify-content: start;

        .casto-input {
          max-width: 130px;
        }
      }
    }
  }

  .ngx-datatable.material:not(.cell-selection) {
    border-radius: 2px;

    .datatable-header {
      &-cell {
        color: black;
        font-size: .9em !important;
        padding: 0.2em 1em !important;
      }
    }


    .datatable-body-row {
      cursor: pointer;

      .datatable-body-cell {
        transition: all 200ms ease-in-out;
        border-top: none !important;

        .datatable-body-cell-label {
          ng-casto-btn {
            padding: 0;
            min-height: 0;
            min-width: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      &:hover .datatable-body-cell {
        background-color: $light-blue-hover !important;
      }
    }

    .datatable-row-wrapper:nth-of-type(odd) .datatable-body-row .datatable-body-cell{
      background-color: $light-blue;
    }

    .datatable-footer {
      .datatable-pager li.active a {
        background-color: $primary-color;
        border-radius: $border-radius;
      }
    }
  }

  .rights-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

ng-casto-update-role, ng-casto-add-role {
  .sign-in-row {
    margin-top: 0.5rem;
  }

  .p-dropdown.p-component {
    width: 300px;
  }

  .p-dropdown-items-wrapper {
    max-height: 300px !important;
  }
}
