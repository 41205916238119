ng-casto-header {
  position: relative;
  display: block;
  z-index: 999;
  padding: 0;

  .header-container {
    box-shadow: 0 2px 4px 0 #00000026;
    position: fixed;
    height: $header-height;
    width: 100vw;
    top: 0;
    transition: max-width 0.3s ease-out;
    margin:auto;
    background-color: $white;
  }

  .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    & > .nav-btn {
      display: none;
    }

    & > #nav-check {
      display: none;
    }
  }

  &.configuration {
    .header-container {
      max-width: 100%;
    }
  }

  .logo-nav{
    outline:none;

    .brand-logo {
      height:3rem;
      margin-top: 8px;
      margin-right: 8px;
      outline:none;
      cursor: pointer;
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 4px;

    .nav-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 15px;
      font-size: 1em;
      color: $darker-grey;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 200ms ease-in-out;
      border-radius: $border-radius;
      margin: 0 3px;

      &:hover {
        background-color: $light-blue-hover;
      }

      &.active {
        color: $primary-color;
        font-weight: bold;
        background-color: $light-blue;
      }

      .user-icon, .logout-icon, .nav-icon {
        display: inline-block;
        margin-right: 10px;

        & > div {
          border-radius: 15px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height:30px;
          width:30px;

          & > svg {
            max-width: 100%;
          }
        }

        svg {
          align-self: center;
        }
      }

      .nav-icon > div > svg {
        max-width: 80%;
        max-height: 80%;
        fill: $primary-color;
      }

      &.nav-user {
        background-color: $lighter-grey;
      }

      .user-info {
        text-align: left;
        font-weight: bold;
      }

      .user-matricule {
        font-size: .8em;
        font-weight: normal;
      }

      .user-icon {
        margin-right: 10px;
        margin-left: 0;
      }

      .logout-icon {
        margin-right: 0;
        margin-left: 7px;
        position: relative;

        & > div {

          & > svg {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
          }
        }
      }

      &.nav-mobile {
        display: none;
      }
    }
  }
  .sidebar-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .sidebar-wrapper {
      position: fixed;
      left: 0;
      height: calc(100vh - $header-height);
      width: 20%;
      min-width: 170px;
      flex: 1;
      border-top: 1px solid #d0d9e1;

      nav {
        display: flex;
        flex-direction: column;
        justify-content: start;
        background-color: white;
        padding: .5em;
        height: 100%;

        .nav-item {
          padding: 1em;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $darker-grey;
          font-weight: 500;
          transition: background-color 200ms ease-in-out;
          border-radius: $border-radius;
          margin-bottom: .5em;

          .nav-icon {
            display: inline-block;
            margin-right: 10px;

            & > div {
              border-radius: 15px;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              height:22px;
              width:22px;

              & > svg {
                max-width: 100%;
                fill: $primary-color;
              }
            }
          }

          &:hover {
            background-color: $primary-color-transparent;
          }

          &.active {
            color: $primary-color;
            font-weight: bold;
            background-color: $primary-color-transparent;
          }
        }
      }

    }
    &.sticky {
      position: fixed;
      top: $header-height;
      width: 20%;

      .sidebar-wrapper {
        height: calc(100vh - $header-height);
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  ng-casto-header {
    font-size: .9em;

    nav .nav-container {
      padding: 0 7px;
      margin: 0 3px;

      .nav-icon {
        margin-right: 5px;

        & > div > svg {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 1205px) {
  ng-casto-header {
    .header-wrapper {
      justify-content: flex-end;

      & > .nav-btn {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        & > label {
          display: inline-block;
          width: 50px;
          height: 50px;
          padding: 13px;

          & > span.burger {
            display: block;
            width: 25px;
            height: 10px;
            border-top: 2px solid $primary-color;
          }
          & > span.times {
            display: none;
            width: 100%;
            height: 100%;
            svg {
              width: 100%;
              height: 100%;
              fill: $primary-color;
            }
          }
        }
      }

      & > nav {
        position: absolute;
        display: block;
        width: 100%;
        background-color: white;
        height: 0;
        overflow-y: hidden;
        top: 55px;
        left: 0;
        transition: height 200ms ease-in-out;

        .nav-container {
          padding: 10px 15px;

          &.nav-mobile {
            display: flex;
          }
        }
      }

      & > #nav-check{
        &:not(:checked) ~ nav {
          height: 0;
        }

        &:checked {

          & ~ .nav-btn > label > span {
            &.burger {
              display: none;
            }

            &.times {
              display: block;
            }
          }

          & ~ nav {
            height:100vh;
            overflow-y: auto;
          }
        }
      }
    }

    .intro-container {
      .picture-wrapper {
        display: none;
      }
    }

    .sidebar-container {
      display: none;
    }
  }
}

@media screen and (max-width: 1205px) {
  ng-casto-header {
    padding: 0;
  }
}
