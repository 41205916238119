/* You can add global styles to this file, and also import other style files */

@import "bootstrap";

@import "scss/variables";
@import "scss/content";
@import "scss/fonts";
@import "scss/print";
@import "scss/modals";
@import "scss/ui";
@import "scss/pages";

@import "primeicons/primeicons.css";