ng-casto-common-create-card-personal-informations {
  .form-card {
    .form-group {
      .form-row {
        max-width: 800px;

        & + .form-row {
          margin-top: 1.5em;
        }

        &:last-child {
          margin-bottom: 1.5em;
        }

        &.form-inline {
          margin-bottom: 0;

          & > label {
            &:first-child {
              margin-right: 7em;
            }
          }
        }

        label {
          &.required {
            &::after {
              right: -6em;
              content: "* (obligatoire)";
              font-size: 1.1em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  ng-casto-common-create-card-personal-informations {
    .form-row-inline-sm {
      flex-direction: column !important;

      .form-col {
        & + .form-col {
          margin-top: 1.5em !important;
        }
      }
    }
  }
}