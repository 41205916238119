ng-casto-customer-home {
  h1.title {
    position: relative;
    margin-left: 0.5em;
    font-size: 1.5em;
    margin-bottom: 1.2em;

    .icon {
      position: absolute;
      transform: translateY(-50%);
      left: -1.27em;
      top: 12px;
      width: 1em;
      height: 18px;
      border-radius: 0 15px 15px 0;
      background-color: #ffdd00;
      text-align: center;
    }
  }

  .intro-container {
    width: 100%;
    background-image: url("/assets/img/intro-background.jpg");
    background-size: cover;
    background-position: center;
    padding: 1em 1em 1em 21%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    z-index: -1;
    color: $primary-color;

    .intro-wrapper {
      display: flex;
      border-radius: 0 50px 0 0;
      background-color: rgba(253, 253, 253, 0.8);
      flex-direction: column;
      justify-content: center;
      padding: 1em;

      h1 {
        font-family: 'Casto Pro Bold';
        font-size: 1.5em;
        position: relative;

        & > span:first-letter {
          text-transform: uppercase;
        }
      }

      h2 {
        margin-top: .5rem;
        display: inline-block;
        font-size: 1.2rem;
        font-family: 'Casto Pro Regular';
        position: relative;


        svg {
          position: absolute;
          bottom: -9px;
          right: 0;
          width: 55px;
          height: 9px;
        }
      }
    }
  }

  .main-container {
    .home-block {
      display: flex;
      flex-direction: column;

      h1 {
        & > span {
          color: $primary-color;
        }
      }
    }

    .form-card {
      background-color: white;
      border-color: $light-blue;
      height: 100%;

      &.home-card {
        background-color: $primary-color;
        border:none;
        text-align: center;

        .title {
          text-align: center;
          color: white;
          text-transform: uppercase;
          position: relative;

          &::before {
            content: '';
            position:absolute;
            width: 2em;
            height: 20px;
            background-color: $yellow-casto;
            top: 50%;
            transform: translateY(-50%);
            left: -1.5em;
            border-radius: 0 1em 1em 0;
          }

          & > span {
            color: $yellow-casto;
            font-weight: bold;
          }
        }

        & > ng-casto-btn {
          margin: 1em auto;
          border-color: white;
          color: $white;
        }

        .statut-block {
          background-color: white;
          border-radius: $border-radius;
          padding: 1.5em 1em;

          & > p {
            margin-bottom: 1em;
            & > span {
              font-weight: bold;
              color: $primary-color;

              &.premium {
                color: $yellow-casto;
              }
            }
          }
        }
      }
    }

    ng-casto-common-customer-points {
      height: 100%;
      width: 100%;
      display: inline-block;
    }

    ng-casto-customer-offer {
      .form-card {
        padding: 0;
        height: 100%;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
          border-radius: $border-radius $border-radius 0 0;
        }

        .offer-infos {
          position: relative;
          padding: 2em 1.5em 1.5em 1.5em;

          ng-casto-btn {
            text-transform: uppercase;
            position: absolute;
            right: 1em;
            top: 0;
            transform: translateY(-50%);
          }

          .offer-title {
            color: $primary-color;
            font-family: 'Casto Pro Bold';
            font-size: 2em;
            margin-bottom: .5em;
          }

          .offer-subtitle {
            color: $primary-color;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: .3em;
          }

          .offer-description {
            font-size: .8em;
          }
        }
      }
    }

    ng-casto-common-customer-card-information {
      height: 100%;

      .form-card {
        height: 100%;

        &.premium {
          background-color: $yellow-casto-secondary;
          border: 1px solid $yellow-casto;
        }

        &.free {
          background-color: $primary-color;
          color: white;

          .card-name, .casto-link {
            color: white;
          }

          .card-state {
            color: black;
          }

          ng-casto-btn {
            border-color: white;

            &.blue {
              background-color: white;
              color: $primary-color;
            }

            &.outline {
              color: white;
            }

            &.yellow {
              color: black;
            }
          }
        }

        .form-group {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    .premium-infos {
      background-color: $yellow-casto-secondary;
      border: 1px solid $yellow-casto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .card-container {
        height: 80px;
        width: 120px;
        position: relative;

        & > svg {
          height: 100%;
          width: 100%;
          filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
        }

        .price {
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          padding: 3px 10px;
          background-color: $yellow-casto;
          border-radius: 20px;
          font-family: "Casto Pro Bold";
        }
      }
      .description-container {
        font-family: 'Casto Pro Bold';
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .primary {
          color: $primary-color;
        }

        .avantages {
          display: flex;
          flex-direction: row;
          vertical-align: middle;

          & > span {
            margin-right: .7em;
            align-items: center;
            display: flex;
            flex-direction: row;
            vertical-align: middle;

            svg {
              margin-right: .3em;
              width: 20px;
              height: 20px;
            }
          }

        }
      }

      .button-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-evenly;

        ng-casto-btn {
          margin-bottom: .3em;
        }
      }
    }

  }

  .institutional-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 3.5em;
    gap: 1em;

    .help-block {
      width: 15em;
      border: 1px solid $primary-color;
      border-radius: $border-radius;
      padding: 20px;
      text-align: center;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $primary-color;

      &:hover {
        background-color: $primary-color-active;
      }
    }
  }
}


@media (max-width: 1205px) {
  ng-casto-customer-home {
    .intro-container {
      padding: 1em;
    }

    .premium-infos {
      flex-direction: column !important;

      .card-container {
        margin: 0 auto 1em;
      }
      .button-container {
        margin-top: .7em;
      }
    }

    h1.title {
      margin-left: 1em;
    }
  }
}
