@font-face {
  font-family: 'Casto Pro Regular';
  src: local('Casto Pro Regular'), local('Casto-Pro-Regular'),
  url('../assets/fonts/CastoPro-Regular/CastoPro-Regular.woff2') format('woff2'),
  url('../assets/fonts/CastoPro-Regular/CastoPro-Regular.woff') format('woff'),
  url('../assets/fonts/CastoPro-Regular/CastoPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Casto Pro Bold';
  src: local('Casto Pro Bold'), local('Casto-Pro-Bold'),
  url('../assets/fonts/CastoPro-Bold/CastoPro-Bold.woff2') format('woff2'),
  url('../assets/fonts/CastoPro-Bold/CastoPro-Bold.woff') format('woff'),
  url('../assets/fonts/CastoPro-Bold/CastoPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
