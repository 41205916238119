ng-casto-customer-informations {
  .main-container {
    ng-casto-loader {
      margin: 0 auto;
    }
  }
  .form-card {
    margin: 0 1em 2em;
    background-color: white;
    border-color: $light-blue;
    display: flex;
    flex-direction: column;

    .form-group {
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .selector-row {
      justify-content: center;

      .casto-selector {
        margin: .5em;
        width: 105px;
      }
    }

    .casto-selector {
      &.card-selector {
        width: fit-content !important;

        & > label {
          padding: 0;
          border: 1px solid transparent;
          background: none;

          & > img {
            max-width: 180px;
            height: 100px;
          }
        }
      }

      &.project-selector {
        input[type="radio"] {
          &+label {
            & > svg {
              max-width: 80px;
              height: 30px;
              fill: $primary-color;
            }
          }

          &:checked+label {
            background-color: $primary-color;
            color: white;
            & > svg {
              fill: white;
            }

            &::after {
              content: none;
            }
          }
        }
      }
    }

    .password-container {
      margin-top: 1em;
      padding: 0 1.5em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span.password {
        font-weight: bold;
      }
    }

    .btn-container ng-casto-btn {
      margin: 1em auto;
    }
  }

  .home-block {
    margin-bottom: 2em;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1em;

    ng-casto-btn {
      margin-right: 1em;
    }
  }
}
