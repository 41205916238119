ng-casto-modals ng-casto-search-product {
  .form-group {
    margin-bottom: 2em;

    .form-row + .form-row {
      margin-top: 1em;
    }
  }

  .btn-block {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
