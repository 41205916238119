ng-casto-card-infos {
  padding: 0 !important;

  header {
    &.is-modal {
      .wrap {
        padding: 3em 0 0 0 !important;

        .header-actions {
          .close {
            top: 10px !important;
          }
        }
      }
    }
  }

  .card-image {
    width: 100%;
    cursor: pointer;
  }

}
