ng-casto-modals ng-casto-card-status {
  table {
    width: 100%;
    margin: 3em 0;

    th {
      text-transform: uppercase;
    }
  }
}
