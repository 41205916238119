ng-casto-vouchers {
  max-width: 900px !important;

  .close {
    top: -35px !important;
  }

  .vouchers-list {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;

    .no-discount {
      flex: 1;
      text-align: center;
      color: $primary-color;
      font-family: 'Casto Pro Bold';
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        width: 50px;
        height: 50px;
        margin-bottom: 1em;
      }
    }

    .voucher {
      background-color: $light-blue-hover;
      border: 1px dashed $primary-color;
      width: 250px;
      height: 18.5em;
      padding: 1.5em 1em;
      border-radius: $border-radius;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }

      &-title {
        color: $primary-color;
        font-family: 'Casto Pro Bold';
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-nb {
          font-weight: 900;
          font-size: 2.5em;
          margin-top: .2em;
          white-space: nowrap;
        }

        &-description {
          font-size: 1.1em;
          white-space: nowrap;
          margin-top: .4em;
        }
      }

      &-description {
        padding: 1.5em 0;

        &-expiration {
          margin-top: 1em;
        }
      }

      &-footer {
        ng-casto-btn {
          padding: 16px 10px;
          white-space: nowrap;
          width: 100%;
        }

        p {
          margin-bottom: 1em;
        }
      }
    }
  }

  .casto-link {
    svg {
      height: 10px;
      width: 23px;
      margin-bottom: 6px;
    }
  }
}

@media (max-width: 991px) {
  ng-casto-modals ng-casto-vouchers {
    width: 95% !important;
    margin: 80px auto !important;
  }
}
