ng-casto-maintenance {
  .maintenance-block {
    margin-top: -56px;
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $primary-color-transparent;

    img {
      height: 80px;
      margin-bottom: 2em;
    }

    h1 {
      margin-bottom: 1em;
    }
  }
}
