ng-casto-logout, ng-casto-colleague-logout {
  background-color: whitesmoke;
  margin: 80px auto;
  padding: 30px 0;
  position: relative;
  width: 450px;
  display: block;

  font-size: 1rem;
  text-align: center;
}
