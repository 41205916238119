.confirmation {
  .confirmation-dialog {
    .footer {
      .confirmation-button {
        position: relative;
        cursor: pointer;
        text-align: center;
        border-radius: $border-radius !important;
        min-height: 50px;
        height: auto;
        min-width: 100px;
        padding: $padding-vertical $padding-horizontal !important;
        transition: background-color 0.3s ease-out, color 0.3s ease-out, opacity 0.3s ease-out;
        font-size: .9em;
        font-family: 'Casto Pro Bold';
        background-color: $primary-color;
        color: $white;

        &:hover, &:focus {
          background-color: $primary-color-active;
        }

        &--reject {
          color: $primary-color;
          border: 1px solid $primary-color !important;
          background-color: $white !important;

          &:hover, &:focus {
            background-color: $primary-color-active !important;
            color: $white;
          }
        }
      }
    }
  }
}

