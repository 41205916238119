@media print {
  @page {
    margin: 0;
  }
  body {
    padding: 1.6cm;
    background-color: white;
    -webkit-print-color-adjust: exact;

    ng-casto-root {
      display: none;
    }

    .print-container {
      display: block;
      text-align: center;
      background-color: white;
      border: 1px solid $light-blue;
      border-radius: $border-radius;
      padding: 1.5em;
    }
  }
}
