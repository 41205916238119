@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

.sk-fading-circle {
  margin: -.8em 0 0 -.8em;
  width: 1.6em;
  height: 1.6em;
  position: absolute;
  top: 50%;
  left: 50%;

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 11%;
      height: 11%;
      background-color: $white;
      border-radius: 100%;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }

    $base-deg: 30;
    $base-delay: -1.1;
    @for $i from 2 through 12 {
      &.sk-circle#{$i} {
        transform: rotate(#{$base-deg}deg);

        &:before {
          animation-delay: #{$base-delay}s;
        }
      }
      $base-deg: $base-deg + 30;
      $base-delay: $base-delay + .1;
    }
  }
}

ng-casto-btn{
  &.light-grey {
    .sk-fading-circle {
      .sk-circle {
        &:before {
          background-color: $dark-grey;
        }
      }
    }
  }
}
