@import "home";
@import "create-card";
@import "customer-informations";
@import "customer-history";
@import "login";
@import "customer-vouchers";

#teconsent {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(253, 253, 253, 0.8);
  z-index: 99999;
  padding: 0.5em 0.8em;
}