ng-casto-btn {
  position: relative;
  cursor: pointer;
  text-align: center;
  border-radius: $border-radius;
  display: inline-block;
  min-height: 50px;
  height: auto;
  min-width: 100px;
  padding: $padding-vertical $padding-horizontal;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, opacity 0.3s ease-out;
  font-size: .9em;
  font-family: 'Casto Pro Bold';

  .disabled-area {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
  }

  &.isLoading {
    pointer-events: none;
  }

  &.disabled {
    cursor:not-allowed;
  }

  &.blue {
    background-color: $primary-color;
    color: $white;

    &:hover, &:focus {
      background-color: $primary-color-active;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.white {
    background-color: $white;
    color: $primary-color;

    &:hover, &:focus {
      background-color: $lighter-grey;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.yellow {
    background-color: $yellow-casto;

    &:hover, &:focus {
      background-color: $yellow-casto-active;
    }
  }

  &.warn {
    color: $error;
  }

  &.outline {
    color: $primary-color;
    border: 1px solid $primary-color;

    &:hover, &:focus {
      background-color: $primary-color-active;
      color: $white;
    }
  }

  &.red {
    background-color: $error !important;
    color: $white !important;

    &:hover, &:focus {
      background-color: $text-error !important;
    }

    &.disabled {
      opacity: 0.5 !important;
    }
  }

  &.light-grey {
    background-color: $light-grey;
    color: $darker-grey;

    &:hover, &:focus {
      background-color: $light-grey-active;
      color: $darker-grey;
    }
  }

  &.text {
    transition: color 0.3s ease-out;

    span {
      font-weight: 500;
      text-decoration: underline;
      color: $primary-color;
    }


    &:hover, &:focus {

      span {

        outline: none;
        text-decoration: none;
        color: $primary-color-active;
      }

    }


  }

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    vertical-align: middle;
  }

  &.btn-link {
    padding: 0;
    text-decoration: underline;

    span {
      display: initial;
    }
  }

  span {
    vertical-align: middle;
    //display: inline-block;

    &.transparent {
      opacity: 0;
    }
  }
}

.bt-text {
  font-size: .8rem;
  text-decoration: underline;
  cursor: pointer;

  &:hover, &:focus {
    color: $primary-color;
  }
}
