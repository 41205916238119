html {
  height: 100%;
}

body {
  font-family: 'Casto Pro Regular';
  background-color: $light-blue;
  padding-top: $header-height;
  min-height: 100%;
  font-size: 16px;
  line-height: 18px;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Casto Pro Bold';
}

h1 {
  font-size: 1.5rem;

  &.title {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    position: relative;
    margin-bottom: 1.1em;

    & > .step {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      color: $grey-second;
    }

    & > .icon {
      display: block;

      & > div {
        width: 35px;
        height: 35px;
        border-radius: 17.5px;
        position: relative;

        svg {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translate(-50%, -50%);
          height: 1.2em;
          width: 1.2em;
          fill: $primary-color;
          z-index: 1;
        }
      }

    }

    & > .text {
      align-self: end;
    }
  }

  &.back {
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 1.8em;

    & > .icon {
      display: block;
      margin-right: .7em;

      & > div {
        width: 22px;
        height: 27px;
        border-radius: 17.5px;
        position: relative;
        transition: all 200ms ease-in-out;

        i {
          position: absolute;
          top: 50%;
          right: -8px;
          transform: translate(-50%, -50%);
          font-size: .6em;
          color: $white;
          background-color: $primary-color;
          padding: 5px;
          border-radius: 50%;
          z-index: 1;
        }

        &:hover{
          &::after {
            background-color: $primary-color-active;
          }
        }
      }

    }
  }
}

h2 {
  font-size: 1.2rem;

  &.subtitle {
    padding-left: 2em;
    position: relative;

    & > span {
      align-self: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 1.5em;
      height: .8em;
      border-radius: 0 1em 1em 0;
      background-color: $yellow-casto;
    }
  }
}

h3 {
  font-size: 1.14rem;
}

figcaption {
  margin-top: 20px;
}

.card {

  outline:0;

  &.card-svg {
    height: 100%;
    max-height: 250px;
    padding-top: 30px;
    margin-top: 15vh;
  }

  svg {
    width: 40%;
    height: 80px;
    margin: 40px auto 0;
    fill: $primary-color;
  }
}

a {
  color: $darker-grey;
}

.main-container {
  padding-top: 3em;
  padding-bottom: 1.5em;
  background-color: $light-blue;

  &:not(.customer) {
    min-height: calc(100vh - $header-height);
  }

  &.customer {
    min-height: calc(100vh - $header-height - 192px);
    padding-left: 21%;
  }
}

.carousel {
  border: 1px solid $light-grey;
  background-color: $lighter-grey;
  padding: 1em 3em 2em;

  &-indicators {
    margin-bottom: 0;

    & [data-bs-target] {
      background-color: $dark-grey;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  &-control-prev {
    justify-content: start;
    color: $darker-grey;
    opacity: .9;
    filter: invert(1);
    padding-left: .5em;
  }
  &-control-next {
    justify-content: end;
    color: $darker-grey;
    opacity: .9;
    filter: invert(1);
    padding-right: .5em;
  }
}

.casto-alert {
  font-size: .8em;
  padding: $padding-vertical $padding-horizontal;
  background-color: $error;
  border: 1px solid $text-error;
  border-radius: $border-radius;
  margin-bottom: 1em;
}

.casto-link {
  text-decoration: underline;
  cursor: pointer;
  color: $grey-second;
  transition: all 200ms ease-in-out;
  font-family: 'Casto Pro Bold';
}

@media (max-width: 1205px) {
  .main-container.customer {
    padding-left: 0;
  }
}
