ng-casto-collegue-search-customer {
  .title-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ng-casto-btn {
      margin-right: 2em;
      padding: 0 1em;
      font-size: 1em;
      position: relative;

      &::after {
        content: '';
        width: 2.5em;
        height: 15px;
        background-color: $yellow-casto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 7px 0 0 7px;
        right: -2em;
      }
    }
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .filter-block {
    display: flex;
    flex-direction: row !important;

    .casto-input {
      flex: 1;
      margin: 0 .5em;
      font-size: .9em;

      &:focus {
        background-color: white;
        box-shadow: 0px 0px 10px 0px #0000001a;
      }
    }

    .casto-input-group {
      flex: 2.5;
      margin: 0 .5em;

      .casto-input {
        margin: 0;
        border: 1px solid $light-grey-active;

        &:first-child {
          margin-right: -1px;
        }
      }
    }

    ng-casto-btn {
      margin: 0 .5em;
      padding: .1em;
      min-width: 50px;
      border-radius: 2px;
      font-size: 1.4em;
    }

    .or {
      width: 100%;
      text-align: center;
      align-self: center;
      position: relative;

      &.small-or {
        width: auto;
        margin: 0 .5em;

        &::before, &::after {
          display: none;
        }
      }
    }

    abp-validation-error {
      display: none;
    }
  }

  #form-search-customer {
    margin-bottom: 0;

    .form-row + .form-row {
      margin-top: 1.7em;

      a {
        padding-left: 1%;
        font-weight: bold;
        font-size: 0.9em;
        color: $grey-second;
      }
    }
  }

  .result-table {
    padding: 0 .5em;

    .ngx-datatable.material:not(.cell-selection) {
      border-radius: 2px;

      .datatable-header {
        &-cell {
          color: black;
          font-size: .9em !important;
          padding: 0.2em 1em !important;
        }
      }


      .datatable-body-row {
        cursor: pointer;

        .datatable-body-cell {
          transition: all 200ms ease-in-out;
          border-top: none !important;

          .datatable-body-cell-label {
            ng-casto-btn {
              padding: 0;
              min-height: 0;
              min-width: 0;

              &::before {
                display: none;
              }
            }
          }
        }

        &:hover .datatable-body-cell {
          background-color: $light-blue-hover !important;
        }
      }

      .datatable-row-wrapper:nth-of-type(odd) .datatable-body-row .datatable-body-cell{
        background-color: $light-blue;
      }

      .datatable-footer {
        .datatable-pager li.active a {
          background-color: $primary-color;
          border-radius: $border-radius;
        }
      }
    }

    .name {
      cursor: pointer;
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        color:$primary-color-active;
      }
    }

    .cardType {
      color: $text-error;

      &.Gratuite {
        background-color: $primary-color;
        color: white;
        padding: 5px 20px;
        border-radius: 20px;
      }

      &.Premium {
        background-color: $yellow-casto;
        color: $primary-color;
        font-weight: bold;
        padding: 5px 20px;
        border-radius: 20px;
      }
    }

    .iconBtn {
      color: $primary-color;
      font-size: 1.2em;
      cursor: pointer;

      &:hover {
        color:$primary-color-active;
      }
    }

    .subscribeBtn {
      background-color: $error;
      cursor: pointer;
      color: white;
      padding: 5px 20px;
      border-radius: 20px;

      &:hover {
        background-color: $text-error;
      }
    }

  }

  .create-btn {
    text-align: center;

    small {
      font-weight: bold;
    }

    ng-casto-btn {
      margin: 1.7em;
      position: relative;

      // &::after {
      //     content: '';
      //     width: 2.3em;
      //     height: 15px;
      //     background-color: $yellow-casto;
      //     position: absolute;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     border-radius: 7px;
      //     right: -1.2em;
      // }
    }
  }
}

@media (max-width: 767px) {
  ng-casto-collegue-search-customer {
    .form-row {
      flex-direction: column !important;

      &-inline {
        flex-direction: row !important;

        .form-col:last-child {
          flex: 1.5 !important;
        }
      }

      .form-col-large, .form-col {
        text-align: center;

        .or {
          margin: 1em 0.5em !important;
        }
      }
    }
  }
}
