p {
  margin: 0;

  &.custom-form-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    &.form-no-wrap {
      flex-wrap: nowrap;
    }

    &.form-checkbox {
      input[type=radio],
      input[type=checkbox] {
        vertical-align: middle;
      }

      span {
        width: 100%;
        margin-bottom: 5px;
      }

      label {
        position: static;
        display: inline-block;
        margin-left: 10px;
        color: $darker-grey;
        font-weight: 500;
        font-size: 0.8rem;
      }
    }

    select {
      padding: 23px 25px 10px 20px;
      border: 1px solid $light-grey;
      font-size: .7rem;
      background-color: $white;
      outline-color: $primary-color;
    }

    span {
      position: relative;

      &.error {
        font-size:  .7rem;
        color: $text-error;
        margin: 5px 0;
      }
    }

    label {
      display: block;
      position: absolute;
      top: 10px;
      left: 25px;
      margin-bottom: 0;
      color: $dark-grey;
      font-weight: lighter;
      font-size: .7rem;
      z-index: 3;
    }

    input[type="text"],
    input[type="search"],
    input[type="password"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="url"],
    textarea {
      width: 100%;
      padding: 25px 25px 10px 25px;
      border: 1px solid $light-grey;
      font-size: .7rem;
      outline-color: $primary-color;

      &:focus {
        z-index: 2;
      }
    }

    input[type="text"] {
      width: 100%;
    }

    &.error {

      input[type="text"],
      input[type="search"],
      input[type="password"],
      input[type="tel"],
      input[type="email"],
      input[type="number"],
      input[type="url"],
      textarea {
        border: 1px solid $error;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -3px;
        top: -3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: $error;
      }
    }

    select.error, input.error {
      border: 1px solid $error;
    }
  }

  &.form-message-error {
    font-size:  .7rem;
    color: $text-error;
    margin: 5px 0;
  }
}

p.custom-form-row + p.custom-form-row {
  margin-top: -1px;
}
p.custom-form-row.error + p.custom-form-row {
  margin-top: 0;
}

.panel-form {
  text-align: left;

  &.error {
    input[type="text"],
    input[type="search"],
    input[type="password"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="url"],
    textarea {
      border: 1px solid $error;
    }

    p .custom-form-row:after {
      content: '';
      display: block;
      position: absolute;
      right: -3px;
      top: -3px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: $error;
    }
  }
}

.form-card {
  background-color: white;
  border: 1px solid $blue-border;
  border-radius: $border-radius;
  padding: 1.5em;

  &-large {
    .form-group {
      padding: 0 3.5em;
    }
  }

  .form-title {
    position: relative;
    margin-left: 0;
    font-size: 1.2em;
    margin-bottom: 1.2em;

    .icon {
      position: absolute;
      transform: translateY(-50%);
      left: -1.25em;
      top: 9px;
      width: 1em;
      height: 15px;
      border-radius: 0 15px 15px 0;
      background-color: $yellow-casto;
      text-align: center;
    }

    a {
      float: right;
      font-size: 1rem;
      text-transform: none;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $primary-color-active;
        text-decoration: underline;
      }

      & > svg {
        width: 1.2em;
        height: 1.2em;
        fill: $primary-color;
      }
    }
  }

  .form-group {
    &-title {
      position: relative;
      color: $primary-color;
      font-size: 1em;
      margin-top: 1em;
      margin-left: 8px;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: -25px;
        width: 20px;
        height: .8em;
        background-color: $primary-color;
        border-radius: 0 .5em .5em 0;
      }
    }

    .form-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;

      & + .form-row {
        margin-top: 1em;
      }

      &:last-child {
        margin-bottom: 1em;
      }

      label {
        font-size: .9em;
        position: relative;
        width: fit-content;
        margin-bottom: 0.2em;

        &.required::after {
          position: absolute;
          content: '*';
          top: 0;
          right: -8px;
          color: $error;
        }
      }

      .form-col {
        flex: 1;
        margin: 0 .5em;
        display: flex;
        flex-direction: column;

        &-large {
          flex: 3;
          margin: 0 .5em;
          display: flex;
          flex-direction: column;
        }

        .input-icon {
          position: relative;

          svg {
            width: 17px;
            height: 17px;
            position: absolute;
            top: 16.5px;
            left: 16.5px;
            cursor: pointer;
          }

          input {
            padding-left: 50px;
          }
        }
      }

      &.form-inline {
        margin: 0.5em;

        & > label {
          margin-right: 1.5em;
          align-self: center;
          margin-top: 0.3em;
        }
        & > input {
          margin-right: .3em;
        }
      }

      &.selector-row {
        justify-content: space-evenly;
        gap: 1em;
      }

      &-error {
        margin: 0 0.5em 1.5em 0.5em;
      }
    }
  }

  h2.toggle {
    cursor: pointer;
    position: relative;
    user-select: none;

    &::after {
      position: absolute;
      content: '\25bc';
      top:0;
      right: 0;
    }

    &.active::after {
      content: '\25b2';
    }
  }
  h3 {
    color: $grey-second;
    font-size: 1em;
    margin: .5em;
    text-decoration: underline;
    text-align: center;
    font-family: 'Casto Pro Regular';

    &.toggle {
      cursor: pointer;
      user-select: none;
      position: relative;

      &::after {
        position: absolute;
        content: '\25bc';
        top:0;
        right: 50%;
        transform: translateX(130px);
      }

      &.active::after {
        content: '\25b2';
      }
    }
  }
}

.casto-input {
  width: 100%;
  padding: 14px 12px;
  border: 1px solid $light-grey-active;
  font-size: 1rem;
  outline-color: $primary-color;
  box-shadow: 0px 1px 3px 0px #2F2B431A;
  background: white;

  &:focus {
    z-index: 2;
    color: $primary-color;
  }

  &.ng-touched.ng-invalid {
    border: 2px solid $error;
    color: $text-error;
  }

  &-group {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-abp-validation {
      div {
        width: 100%;
      }

      button, ng-casto-btn {
        height: fit-content;

        &.red {
          min-height: 52px;
        }

        .sk-fading-circle {
          margin: -0.8em 0 0 -3.4em;
        }
      }
    }

    input {
      border-right: none;
      margin: 0;
    }

    button, ng-casto-btn {
      appearance: none;
      border: none;
      background-color: $primary-color;
      color: white;
      padding: 1em 1.5em;
      border-radius: 0 $border-radius $border-radius 0;

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }

      & > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .spinner-border {
          border-top-color: white;
          border-left-color: white;
          border-bottom-color: white;
          height: 15px;
          width: 15px;
        }

        svg {
          fill: white;
          height: 15px;
          width: 15px;
        }
      }

    }
  }

  &[disabled], &[readonly] {
    opacity: .7;
    background-color: $lighter-grey;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.castoInputError {
  border: red 1px solid;
}

.casto-selector {
  flex: 1 1 0px;

  input[type="radio"], input[type="checkbox"] {
    display: none;

    &+label {
      padding: 12px 8px;
      border: 1px solid $blue-border;
      border-radius: $border-radius;
      background-color: white;
      transition: all 200ms ease-in;
      text-align: center;
      cursor: pointer;
      width: 100% !important;
      height: 100%;

      & > img, & > svg {
        max-width: 100%;
        height: 64px;
      }

      & > p {
        margin: .5em 0 0 0;
        text-align: center;
        word-break: normal;
        hyphens: auto;
        font-weight: bold;
        font-family: 'Casto Pro Bold';
        font-size: 16px;
      }

      &:hover {
        border-color: $primary-color-active;
      }
    }

    &:checked+label {
      border-color: $primary-color;
      border-width: 2px;
      background-color: $light-blue;
      position: relative;

      &::after {
        position: absolute;
        text-align: center;
        line-height: 24px;
        content: '✔';
        color: white;
        width: 20px;
        height: 20px;
        top: -10px;
        right: -10px;
        border-radius: 10px;
        background-color: $primary-color;
      }

      & > p {
        color: $primary-color;
      }
    }

    &:disabled+label {
      opacity: .5;
      pointer-events: none;
    }
  }
}

.casto-text-selector {

  input[type="radio"] {
    display: none;

    &+label {
      padding: 5px;
      cursor: pointer;
      border: 1px solid $primary-color;
      background-color: white;
      transition: all 200ms ease-in;

      & > p {
        margin: .5em 0 0 0;
        text-align: center;
      }

      &:hover {
        background-color: $primary-color-active;
        color: white;
      }

      &:not(:first-child){
        margin-left: -1px;
      }

      &:first-of-type {
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border-radius: 0 2px 2px 0;
      }
    }

    &:checked+label {
      background-color: $primary-color;
      color: white;
    }
  }
}

.form-switch .form-check-input:checked {
  background-color: $primary-color !important;
  border: none;
}

.form-check-label {
  vertical-align: middle;
}

small.error {
  color: $text-error;
}

small ul li {
  list-style-type: '- ';
  margin-left: 1em;
}

@media screen and (max-width: 767px) {
  .casto-selector {
    input[type="radio"], input[type="checkbox"] {
      &+label {
        & > img, & > svg {
          height: 32px;
        }
      }
    }
  }

  .form-card {
    &-large {
      .form-group {
        padding: 0;
      }
    }
  }
}
