ng-casto-common-customer-points {
  .form-card {
    display: flex;
    flex-direction: column;
  }
  .progress-points {
    position: relative;
    width: 95%;
    padding: 0 20px;
    margin: 2em auto 0;

    canvas {
      width: 100% !important;
      height: 70px !important;
    }

    .min-points, .max-points {
      position: absolute;
      color: $primary-color;

      & > .nb {
        font-weight: bold;
      }
    }

    .min-points {
      left: 0;
    }

    .max-points {
      right: 0;
    }

    #actual-points {
      position: absolute;
      color: black;
      bottom: -5px;
      left: 0;
      padding: 5px 10px;
      background-color: $yellow-casto;
      word-wrap: unset;
      border-radius: 20px;

      & > .text {
        white-space: nowrap;
        font-size: 12px;

        & > .nb {
          font-size: 18px;
          font-family: 'Casto Pro Bold';
        }
      }

      &::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 70%;
        background-color: $yellow-casto;
        right: 50%;
        top: -70%;
      }
    }

    &::before {
      position: absolute;
      content: "";
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $primary-color;
    }

    &::after {
      position: absolute;
      content: '';
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $light-blue-hover;
    }

  }

  .total {
    width: 82%;
    margin: 0 auto;
    font-weight: bold;
    margin-top: -.7em;

    & > span {
      color: $primary-color;
    }
    font-size: 1.2em;
    text-align: right;
  }

  .remaining {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 1.3em;
      & > span {
        color: $primary-color;
      }
      text-align: left;
    }
    p {
      font-weight: bold;
      text-transform: uppercase;
      & > span {
        color: $primary-color;
      }
      text-align: left;
    }
    ul {
      text-align: left;

      li {
        font-size: .9em;
        &::before {
          content: "\2022";
          color: $primary-color;
          font-weight: bold;
          font-size: 1.5em;
          display: inline-block;
          width: .7em;
        }

        & > span {
          font-weight: bold;
        }
      }
    }
    ng-casto-btn {
      margin: 1em auto;
    }
  }

  .no-discount {
    text-align: center;
    color: $primary-color;
    font-family: 'Casto Pro Bold';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    svg {
      width: 50px;
      height: 50px;
      margin-bottom: 1em;
    }
  }

  .discounts {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .discount {
      background-color: $light-blue-hover;
      border: 1px dashed $primary-color;
      padding: 1.5em 1em;
      border-radius: $border-radius;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1em;
      align-items: center;

      &-body {
        display: flex;
        gap: 1em;

        .discount-title {
          color: $primary-color;
          font-family: 'Casto Pro Bold';
          display: flex;
          flex-direction: column;
          justify-content: center;

          &-nb {
            font-weight: 900;
            font-size: 2.5em;
            margin-bottom: .2em;
            white-space: nowrap;
            line-height: 0.8em;
          }

          &-description {
            font-size: 1.1em;
            white-space: nowrap;
          }

          svg {
            width: 50px;
            height: 50px;
            margin-bottom: 1em;
          }
        }

        .discount-description {
          line-height: 1.2em;

          &-expiration {
            margin-top: .5em;
          }

          strong {
            color: $primary-color;
          }
        }
      }
    }

    ng-casto-btn {
      padding: 16px 20px;
      white-space: nowrap;
      height: 50px;
    }
  }
}

@media (max-width: 991px) {
  ng-casto-common-customer-points {
    .discounts {
      .discount {
        .no-discount {
          flex: inherit;
        }
      }
    }
  }
}

@media (max-width: 1399px) {
  ng-casto-common-customer-points {
    .discounts {
      .discount-body {
        justify-content: space-evenly;
        width: 100%;
      }
    }
  }
}
